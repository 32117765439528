import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { config } from '@app/app.config';
import { UserData } from '@app/models/auth';

export const USER_LOCAL_STORAGE_KEY = 'app-admin-user';

@Injectable({
  providedIn: 'root',
})
export class AuthAppService {
  private userTokenSubject: BehaviorSubject<string>;
  public currentUserToken: Observable<string>;
  private userProfileSubject: BehaviorSubject<UserData>;
  public currentProfileData: Observable<UserData>;

  constructor(private http: HttpClient) {
    this.userTokenSubject = new BehaviorSubject<string>(null);
    this.currentUserToken = this.userTokenSubject.asObservable();
    this.userProfileSubject = new BehaviorSubject<UserData>(null);
    this.currentProfileData = this.userProfileSubject.asObservable();
  }

  public get currentTokenValue(): any {
    return this.userTokenSubject.value;
  }

  updateUserToken(token: string): void {
    this.userTokenSubject.next(token);
  }

  public get currentProfileValue(): UserData {
    return this.userProfileSubject.value;
  }

  updateProfileData(prifile: UserData): void {
    this.userProfileSubject.next(prifile);
  }

  getUserInfo(): Observable<UserData> {
    return this.http.get<UserData>(`${config.apiUrl}/api/v1/auth/userinfo`).pipe(
      tap((resp) => {
        this.updateProfileData(resp);
      })
    );
  }
}
