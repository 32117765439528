import { AuthAppService } from '@app/core/services/auth-app.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthAppGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthAppService, private auth: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authenticationService.getUserInfo().pipe(
      map((userData) => {
        if (userData) {
          this.authenticationService.updateProfileData(userData);
          return true;
        } else {
          return false;
        }
      })
    );
  }
}
