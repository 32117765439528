import { Injectable } from '@angular/core';
import {
  SortParams,
  QueryParams,
  CustomQueryParamsParser,
  PageParams,
  PageData,
  PageDataLoader,
  PaginationParams,
  LoadingConsumer,
} from '@app/models/page';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable, Subject, zip } from 'rxjs';
import { map, switchMap, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { PageUtil } from '@app/helper/page.util';
import { CopyUtil } from '@app/helper/copy.util';

@Injectable()
export class PageService {
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  init<T, TF>(
    pageDataLoader: PageDataLoader<T>,
    parser: CustomQueryParamsParser<TF>,
    loading: LoadingConsumer,
    unsubscribe$: Subject<any>,
    customSortParams?: SortParams
  ): Observable<PageParams<T, TF>> {
    const queryParams$: Observable<QueryParams<TF>> = this.rawQueryParams$.pipe(
      tap(() => loading(true)),
      map((rawParams) => PageUtil.rawParamsToQueryParams(rawParams, parser, customSortParams)),
      shareReplay()
    );
    const pageData$: Observable<PageData<T>> = queryParams$.pipe(
      map(PageUtil.flattenQueryParams),
      switchMap(pageDataLoader),
      shareReplay()
    );
    return zip(queryParams$, pageData$).pipe(
      map(([queryParams, pageData]) => PageUtil.toPageParams(queryParams, pageData)),
      tap(() => loading(false)),
      takeUntil(unsubscribe$)
    );
  }

  onFilter(filterParams: any) {
    const queryParams = CopyUtil.deepCopy<any>(this.rawQueryParamsSnapshot);
    queryParams.pageNumber = 1;
    const result = PageUtil.flattenQueryParamsOrOld(queryParams, filterParams);
    this.reloadPageWithParams(result);
  }

  onSort(sortParams: SortParams) {
    this.execute(sortParams);
  }

  onPagination(paginationParams: PaginationParams, el?: HTMLElement) {
    if (el && el.offsetParent !== null) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
    this.execute(paginationParams);
  }

  private execute(params: any): void {
    const result = PageUtil.flattenQueryParamsOrOld(this.rawQueryParamsSnapshot, params);
    this.reloadPageWithParams(result);
  }

  private reloadPageWithParams(params: any): void {
    for (const pKey in params) {
      if (!params[pKey]) {
        delete params[pKey];
      }
    }
    this.router.navigate([], { queryParams: params });
  }

  private get rawQueryParams$(): Observable<Params> {
    return this.activatedRoute.queryParams;
  }

  private get rawQueryParamsSnapshot(): Params {
    return this.activatedRoute.snapshot.queryParams;
  }
}
