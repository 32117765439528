import { AuthTokenGuard } from './guards/auth-token.guard';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthAppService } from '@app/core/services/auth-app.service';
import { AlertService } from './services/alert.service';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { DateInterceptor } from './interceptors/date.interceptor';
import { DefaultHeadersInterceptor } from './interceptors/default-headers.interceptor';
import { CommonModule } from '@angular/common';
import { PageService } from './services/page.service';
import { AuthModule } from '@auth0/auth0-angular';
import { PermissionsHelperService } from './services/permissions-helper.service';
import { AuthAppGuard } from './guards/auth-app.guard';
import { CanAccessAppGuard } from './guards/can-access-app.guard';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

/* Add only singleton services that need to be reused in the App root */
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 7000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      closeButton: true,
    }),
    AuthModule.forRoot({
      domain: 'dev-6295pdoq.eu.auth0.com',
      audience: 'onfield-main-api',
      clientId: 'n4goEUXeUmUHubO5bEImx5jdWtvZQ5EA',
    }),
  ],
  declarations: [],
  exports: [TranslateModule, CommonModule, HttpClientModule, ReactiveFormsModule, FormsModule],
  providers: [
    TranslateService,
    AuthAppService,
    PermissionsHelperService,
    AuthAppGuard,
    AuthTokenGuard,
    CanAccessAppGuard,
    // UnauthGuard,
    AlertService,
    PageService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DefaultHeadersInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true },
  ],
})
export class CoreModule {
  /* make sure CoreModule is imported only by one NgModule the AppModule */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
