import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHelperService } from './core/services/translate-helper.service';
import { LANGUAGE_CODES, DEFAULT_LANG_CODE } from './models/language';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  title = 'On Field';
  loading: boolean;
  unsubscribe$ = new Subject<any>();

  constructor(private translate: TranslateService, private translateHelper: TranslateHelperService) {
    translate.addLangs(LANGUAGE_CODES);
    translate.setDefaultLang(DEFAULT_LANG_CODE);
    this.translate.use(this.translateHelper.getLanguageCode());
  }
}
