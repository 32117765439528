import { AuthAppService } from '@app/core/services/auth-app.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthAppService, private auth: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.getAccessTokenSilently().pipe(
      map((token) => {
        if (token) {
          this.authenticationService.updateUserToken(token);
          return true;
        } else {
          return false;
        }
      })
    );
  }
}
