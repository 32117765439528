export interface ForgottenPassword {
  email: string;
}

export interface ResetPassword {
  newPassword: string;
  token: string;
}

export interface UserData {
  active: boolean;
  customerId: string;
  email: string;
  emailVerified: boolean;
  privacyConfirmed: boolean;
  termsConfirmed: boolean;
  forcePasswordChange: boolean;
  id: string;
  name: string;
  roles: string[];
  permissions: AllPernissions[];
}

export enum AllPernissions {
  ALLOW_ALL = 'allow-all',
  CUSTOMER_CREATE = 'customer-create',
  CUSTOMER_READ = 'customer-read',
  CUSTOMER_LIST = 'customer-list',
  CUNSTOMER_UPDATE = 'customer-update',
  USER_CREATE = 'user-create',
  USER_DELETE = 'user-delete',
  USER_LIST = 'user-list',
  USER_READ = 'user-read',
  USER_UPDATE = 'user-update',
  DEVICE_CREATE = 'device-create',
  DEVICE_DELETE = 'device-delete',
  DEVICE_LIST = 'device-list',
  DEVICE_READ = 'device-read',
  DEVICE_UPDATE = 'device-update',
  DEVICE_TRANSFER_CREATE = 'device-transfer-create',
  DEVICE_TRANSFER_DELETE = 'device-transfer-delete',
  DEVICE_TRANSFER_LIST = 'device-transfer-list',
  DEVICE_TRANSFER_READ = 'device-transfer-read',
  DEVICE_TRANSFER_UPDATE = 'device-transfer-update',
}
