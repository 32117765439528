import { AuthAppService } from './../services/auth-app.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InterceptorSkipContentTypeHeader } from './skip-interceptor.header';
import { TranslateHelperService } from '../services/translate-helper.service';

@Injectable({
  providedIn: 'root',
})
export class DefaultHeadersInterceptor implements HttpInterceptor {
  constructor(private authAppService: AuthAppService, private translateHelperService: TranslateHelperService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken: string = this.authAppService.currentTokenValue;

    if (!!accessToken) {
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + accessToken), withCredentials: true });
    }

    if (!request.headers.has('Accept')) {
      request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
    }

    if (request.headers.has(InterceptorSkipContentTypeHeader)) {
      const headers = request.headers.delete(InterceptorSkipContentTypeHeader);
      request = request.clone({ headers });
    } else if (!request.headers.has('Content-Type')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }
    return next.handle(request);
  }
}
