import { AuthTokenGuard } from './core/guards/auth-token.guard';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('@app/pages/login/login.module').then((x) => x.LoginModule),
    // canActivate: [UnauthGuard],
  },
  {
    path: 'app',
    loadChildren: () => import('@app/pages/app-wrapper/app-wrapper.module').then((x) => x.AppWrapperModule),
    canActivate: [AuthGuard, AuthTokenGuard],
  },
  {
    path: '**',
    redirectTo: '/login', // TODO change with 404 page
  },
];

export const routing = RouterModule.forRoot(routes, {
  // enableTracing: true,
  // useHash: true
});
