import { Params } from '@angular/router';
import { Observable } from 'rxjs';

export class PageState {
  loading = false;
  texts: any;

  static withLoading(loading: boolean): PageState {
    const pageState = new PageState();
    pageState.loading = loading;
    return pageState;
  }
}

export enum SortDirection {
  DESC = 'DESC',
  ASC = 'ASC',
}

export enum KnownQueryParams {
  SORT_BY = 'sortBy',
  SORT_ORDER = 'sortDirection',
  PAGE_NUMBER = 'pageNumber',
  PAGE_SIZE = 'pageSize',
}

export interface PageData<T> {
  items: T[];
  totalItems: number;
  pageNumber: number;
  pageSize: number;
  sortBy: string;
  sortDirection: SortDirection;
}

export interface QueryParams<TF> {
  sortBy: string;
  sortDirection: SortDirection;
  pageNumber: number;
  pageSize: number;
  custom: TF;
}

export interface PaginationParams {
  pageNumber: number;
  pageSize: number;
  totalItems: number;
}

export interface SortParams {
  sortBy: string;
  sortDirection: SortDirection;
}

export interface PageParams<T, TF> {
  items: T[];
  filterParams: TF;
  sortParams: SortParams;
  paginationParams: PaginationParams;
}

export interface CustomClickEvent {
  target: any;
  path: any;
}

export type CustomQueryParamsParser<TF> = (rawParams: Params) => TF;

export type PageDataLoader<T> = (flatParams: any) => Observable<PageData<T>>;

export type LoadingConsumer = (loading: boolean) => void;
