import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthAppService } from '@app/core/services/auth-app.service';
import { InterceptorSkipHeader } from './skip-interceptor.header';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authAppService: AuthAppService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    }
    return next.handle(request).pipe(
      catchError((err) => {
        // if (err.status === 401) {
        //   // auto logout if 401 response returned from api
        //   this.authAppService.logout();
        //   // location.reload();
        // } else {
        return throwError(err);
        // }
      })
    );
  }
}
