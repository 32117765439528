import { LoaderModule } from './shared/loader/loader.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from '@app/app.component';
import { routing } from '@app/app.routing';
import { CoreModule } from '@app/core';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, routing, CoreModule, LoaderModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
