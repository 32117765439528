import { AuthAppService } from './auth-app.service';
import { Injectable } from '@angular/core';
import { AllPernissions } from '@app/models/auth';

@Injectable({
  providedIn: 'root',
})
export class PermissionsHelperService {
  constructor(private authAppService: AuthAppService) {}

  get isAdmin() {
    return !!this.authAppService.currentProfileValue.permissions.includes(AllPernissions.ALLOW_ALL);
  }

  get canCreateCustomers() {
    return !!this.authAppService.currentProfileValue.permissions.includes(AllPernissions.CUSTOMER_CREATE);
  }

  get canListCustomers() {
    return !!this.authAppService.currentProfileValue.permissions.includes(AllPernissions.CUSTOMER_LIST);
  }

  get canReadCustomer() {
    return !!this.authAppService.currentProfileValue.permissions.includes(AllPernissions.CUSTOMER_READ);
  }

  get canUpdateCustomers() {
    return !!this.authAppService.currentProfileValue.permissions.includes(AllPernissions.CUNSTOMER_UPDATE);
  }

  get canCreateUsers() {
    return !!this.authAppService.currentProfileValue.permissions.includes(AllPernissions.USER_CREATE);
  }

  get canListUsers() {
    return !!this.authAppService.currentProfileValue.permissions.includes(AllPernissions.USER_LIST);
  }

  get canDeleteUsers() {
    return !!this.authAppService.currentProfileValue.permissions.includes(AllPernissions.USER_DELETE);
  }

  get canUpdateUser() {
    return !!this.authAppService.currentProfileValue.permissions.includes(AllPernissions.USER_UPDATE);
  }

  get canReadUser() {
    return !!this.authAppService.currentProfileValue.permissions.includes(AllPernissions.USER_READ);
  }

  get canCreateDevices() {
    return !!this.authAppService.currentProfileValue.permissions.includes(AllPernissions.DEVICE_CREATE);
  }

  get canListDevices() {
    return !!this.authAppService.currentProfileValue.permissions.includes(AllPernissions.DEVICE_LIST);
  }

  get canDeleteDevices() {
    return !!this.authAppService.currentProfileValue.permissions.includes(AllPernissions.DEVICE_DELETE);
  }

  get canUpdateDevice() {
    return !!this.authAppService.currentProfileValue.permissions.includes(AllPernissions.DEVICE_READ);
  }

  get canReadDevice() {
    return !!this.authAppService.currentProfileValue.permissions.includes(AllPernissions.DEVICE_READ);
  }

  get canCreateTransferDevices() {
    return !!this.authAppService.currentProfileValue.permissions.includes(AllPernissions.DEVICE_TRANSFER_CREATE);
  }

  get isUserFillAllData() {
    return (
      !!this.authAppService.currentProfileValue.emailVerified &&
      !!this.authAppService.currentProfileValue.customerId &&
      !!this.authAppService.currentProfileValue.privacyConfirmed &&
      !this.authAppService.currentProfileValue.forcePasswordChange &&
      !!this.authAppService.currentProfileValue.termsConfirmed
    );
  }
}
