import { PermissionsHelperService } from './../services/permissions-helper.service';
import { AuthAppService } from '@app/core/services/auth-app.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root',
})
export class CanAccessAppGuard implements CanActivate {
  constructor(private router: Router, private permissionsHelperService: PermissionsHelperService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.permissionsHelperService.isUserFillAllData) {
      return true;
    } else {
      this.router.navigate(['/app/profile-info']);
      return false;
    }
  }
}
